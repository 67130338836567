/** Colors **/
$primary-color: #00499e;
$secondary-color: #f1be00;
$button-focus-color: #00406b;
$border-color: #9b9b9b;
$failure-color: #9e002a;

//Fonts
$base-color: #4a4a4a;
$link-color: $primary-color;

$body-font-family: Roboto, sans-serif;
$body-font-size: 16px !default;

body {
  color: $base-color;
  font-family: $body-font-family;
  font-size: $body-font-size;
}

button {
  color: #ffffff;
  background-color: $primary-color;
  border-radius: 4px;
  border-color: $primary-color;
  font-family: inherit;
  font-size: inherit;
  margin: 0.5rem;
  max-width: 292px;
  padding: 0.5rem;
  text-align: center;
  width: 100%;

  &:focus,
  &:hover {
    background-color: $button-focus-color;
  }

  &.remove {
    background-color: #ffffff;
    color: $failure-color;
    border: 1px solid $failure-color;
  }
}

input {
  border: 1px solid $border-color;
  border-radius: 4px;
  padding: 0.5rem;
  margin: 0.5rem;
  max-width: 296px;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.day {
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
  padding: 2rem;
  border-bottom: 1px solid $border-color;
}

.day-totals--hours {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  border-radius: 4px 4px 0 0;
  display: block;
  max-width: 50%;
  border: 1px solid $border-color;
  border-bottom: none;
  text-align: center;
}

.day-totals--decimals {
  border-radius: 0 0 4px 4px;
  border: 1px solid $border-color;
  border-top: none;
  padding: 0.5rem;
  display: block;
  max-width: 50%;
  text-align: center;
}

.stats {
  display: grid;
  grid-template-columns: 2fr 10fr;
  padding: 2rem;
  border-bottom: 1px solid $border-color;
}
